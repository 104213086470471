import React, { useEffect } from 'react';
import { Card, Container, GridCol, Heading, Image, Link, Spinner, Text } from '@legalshield/adonis-ux-framework';
import PaymentDropdown from './PaymentDropdown';
import { ISubscriptionResource } from './hooks/utils/subscription-utils';
import { getLogo } from './utils/utils';
import { getFriendlyProductName } from './utils/getFriendlyName';
import { useHref, useLinkClickHandler } from 'react-router-dom';
import { useGetPaymentMethods } from './hooks/usePaymentMethods';
import { populateTemplateString } from '../../utils/stringFormat';
import { useGetOutstandingInvoices } from './hooks/useGetOutstandingInvoices';
import { SubscriptionStatus, UpdatePaymentMethod } from '@legalshield/frontend-commons/dist/sdk/subscriptions';
import { useGetGroupByGroupId } from './hooks/useGetGroup';
import { PayType } from '@legalshield/frontend-commons/dist/sdk/groups';
import { useUrlParamManager } from '../../hooks/useUrlParamManager';
import { useHandleUpdatePaymentMethod } from './hooks/useUpdatePaymentMethod';
import { usePrecancelModal } from './PrecancelModal/PrecancelModalContext';
import { Users } from '@legalshield/frontend-commons/src/utils/users';

type SubscriptionCardProps = {
  subscription: ISubscriptionResource;
};

const PplsiRouterLink = React.forwardRef<HTMLAnchorElement, any>(
  ({ onClick, replace = false, state, target, to, ...rest }, ref) => {
    const href = useHref(to);
    const handleClick = useLinkClickHandler(to, {
      replace,
      state,
      target,
    });

    return (
      <Link
        {...rest}
        href={href}
        onClick={(event) => {
          onClick?.(event);
          if (!event.defaultPrevented) {
            handleClick(event);
          }
        }}
        ref={ref}
        target={target}
      />
    );
  },
);

PplsiRouterLink.displayName = 'PplsiRouterLink';

export const SubscriptionCard = ({ subscription }: SubscriptionCardProps) => {
  const { data: invoicesData, isLoading: invoicesLoading } = useGetOutstandingInvoices(subscription?.id);
  const { isLoading: paymentsLoading } = useGetPaymentMethods();

  const { clearParameters, getParameter } = useUrlParamManager();
  const resourceIdParam = getParameter('resource_id');
  const subscriptionIdParam = getParameter('subscriptionId');

  const { mutateAsync: updatePaymentMethod } = useHandleUpdatePaymentMethod(subscription?.id);
  const { dispatch } = usePrecancelModal();

  const isPrecancel = subscription?.status === SubscriptionStatus?.PRECANCEL || invoicesData?.outstandingInvoices?.isPrecancel;
  const inPrecancelBiller = invoicesData?.outstandingInvoices?.inPrecancelBiller;

  useEffect(() => {
    if (resourceIdParam && subscriptionIdParam === subscription.id) {
      if (isPrecancel && !inPrecancelBiller) {
        const onPayAndRenewSuccess = () => {
          const updatedPaymentMethod: UpdatePaymentMethod = {
            paymentMethodId: resourceIdParam,
          };
          updatePaymentMethod(updatedPaymentMethod);
          clearParameters();
        };
        dispatch({
          payload: {
            handleClickPayAndRenew: onPayAndRenewSuccess,
            paymentMethodId: resourceIdParam,
            subscription,
          },
          type: 'SHOW_MODAL',
        });
      } else {
        const updatedPaymentMethod: UpdatePaymentMethod = {
          paymentMethodId: resourceIdParam,
        };
        updatePaymentMethod(updatedPaymentMethod);
        clearParameters();
      }
    }
  }, [resourceIdParam, subscription, subscriptionIdParam]);

  const groupId = subscription?.source?.groupId;
  const { data: groupData } = useGetGroupByGroupId(groupId);

  const productBrochure = subscription?.productBrochure;
  const productFamily = productBrochure?.productFamily;
  const logo = getLogo(productFamily);

  const SUBSCRIPTION_AMOUNT_DUE = populateTemplateString(string_table.SUBSCRIPTION_AMOUNT_DUE, {
    price: invoicesData?.outstandingInvoices?.totalBillingAmount.toFixed(2),
  });

  const SUBSCRIPTION_MEMBER_NUMBER = populateTemplateString(string_table.SUBSCRIPTION_MEMBER_NUMBER, {
    memberNumber: subscription?.source?.friendlyId,
  });

  const SUBSCRIPTION_PAST_DUE = populateTemplateString(string_table.SUBSCRIPTION_PAST_DUE, {
    pastDue: invoicesData?.outstandingInvoices?.invoicesBehind.toString(),
  });

  const shouldRenderPaymentDropdown = () => {
    if (!!Users.isImpersonated()) return true;

    if (!groupData) return true;
    if (groupData.payType !== PayType.SELF_PAY) return false;

    return true;
  };

  return paymentsLoading ? (
    <Spinner data-testid="loading-spinner" blocking color="brand" spinnerSize="xlarge" />
  ) : (
    <GridCol classNames={['mb-4']}>
      <Card key={subscription?.id}>
        <Card.Content>
          <Container id="subscription-card" flexbox={true} classNames={['align-items-end mb-5']}>
            <Image
              id="product-logo"
              classNames={['mr-auto']}
              src={'https://design.api.' + pplsi.realmDomain + '/assets/logos/' + logo}
              alt={string_table.PRODUCT_LOGO_ALT}
            />
            <Container id="card-links" flexbox classNames={['flex-column']}>
              <PplsiRouterLink id="product-details-link" to={`/payments/details/${subscription.id}`}>
                {string_table.LINK_PRODUCT_DETAILS}
              </PplsiRouterLink>
            </Container>
          </Container>
          <Heading id="subscription-name" as="T20" text={getFriendlyProductName(subscription)} />
          <Text id="subscription-member-number" textSize="medium" text={SUBSCRIPTION_MEMBER_NUMBER} />
          {isPrecancel && !invoicesLoading && (
            <>
              <Text id="subscription-price" textSize="medium" text={SUBSCRIPTION_AMOUNT_DUE} />
              <Text id="subscription-past-due" textSize="medium" text={SUBSCRIPTION_PAST_DUE} />
            </>
          )}
          {subscription && shouldRenderPaymentDropdown() && (
            <Container
              flexbox
              flexDirection="row"
              background="none"
              alignItems="center"
              classNames={['pt-6']}
              id={`subsciption-${subscription.id}-payment`}
            >
              <PaymentDropdown key={subscription?.paymentMethodId} subscription={subscription} />
            </Container>
          )}
        </Card.Content>
      </Card>
    </GridCol>
  );
};
