export const formatPhoneNumber = (phoneNumberString: string): string => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + ' ' + match[3];
  }
  return '';
};

export const formatPhoneNumberWithDash = (phoneNumberString: string): string => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return '';
};

// yyyy-mm-dd -> Locale specific date string
export const formatDate = (dateString: string): string => {
  const parts = dateString.split('-');
  const date = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));
  return date.toLocaleDateString(pplsi.market, { day: 'numeric', month: 'numeric', year: 'numeric' });
};

// yyyy-mm-dd -> Locale specific date string using 2digit month and day
export const formatDate2Digit = (dateString: string): string => {
  const parts = dateString.split('-');
  const date = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));
  return date.toLocaleDateString(pplsi.market, { day: '2-digit', month: '2-digit', year: 'numeric' });
};

export const uppercaseFirstLetter = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};
