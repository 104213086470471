import React, { FC } from 'react';
import { Container, Icon, Label, Text } from '@legalshield/adonis-ux-framework';
import { Link } from 'react-router-dom';
import { Users } from '@legalshield/frontend-commons/src/utils/users';
import { PayType } from '@legalshield/frontend-commons/dist/sdk/groups';
import Skeleton from 'react-loading-skeleton';

import PaymentDropdown from '../../PaymentDropdown';
import { ISubscriptionResource } from '../../hooks/utils/subscription-utils';
import { SubscriptionBenefitsModal } from './SubscriptionBenefitsModal/SubscriptionBenefitsModal';
import { formatDate } from '../../utils/utils';
import { populateTemplateString } from '../../../../utils/stringFormat';
import { useGetGroupByGroupId } from '../../../Payments/hooks/useGetGroup';

interface SubscriptionDetailsProps {
  subscription: ISubscriptionResource;
  isLoading?: boolean;
}

export const SubscriptionDetails: FC<SubscriptionDetailsProps> = ({ isLoading, subscription }) => {
  const groupId = subscription?.source?.groupId;
  const { data: groupData } = useGetGroupByGroupId(groupId);

  const memberSince = formatDate(subscription?.startedOn);

  const subscriptionMemberNo = populateTemplateString(string_table.SUBSCRIPTION_MEMBER_NUMBER, {
    memberNumber: subscription?.source?.friendlyId,
  });

  const shouldRenderPaymentDropdown = () => {
    if (!!Users.isImpersonated()) return true;

    if (!groupData) return true;
    if (groupData.payType !== PayType.SELF_PAY) return false;

    return true;
  };

  return (
    <>
      <div className="accounts-subscription-item__content__section">
        {isLoading ? (
          <Container background="none">
            <Skeleton count={1} height={20} width={100} className="mb-3" />
            <Skeleton count={1} height={18} width={150} className="mb-2" />
            <Skeleton count={1} height={18} width={130} className="mb-2" />
          </Container>
        ) : (
          <>
            <Label
              text={string_table.SUBSCRIPTIONS_MEMBERSHIP_DETAILS}
              classNames={['accounts-subscription-item__content__section__label']}
            />
            <Text text={subscriptionMemberNo} classNames={['mb-1']} />
            <Text text={`${string_table.PRODUCT_MEMBER_SINCE_DATE} ${memberSince}`} classNames={['mb-1']} />
            {/* This modal is WIP and out of scope for MVP */}
            {/* <SubscriptionBenefitsModal subscription={subscription} /> */}
            <Link to={`/payments/details/${subscription.id}`} className="sub-benefits-modal__link lsux-text--medium">
              {string_table.PAYMENTS_SUBSCRIPTIONS_VIEW_BENEFITS}
            </Link>
          </>
        )}
      </div>
      <div className="accounts-subscription-item__content__section">
        {subscription && shouldRenderPaymentDropdown() && (
          <Container
            flexbox
            flexDirection="row"
            background="none"
            justifyContent="flex-end"
            classNames={['pt-2']}
            id={`subsciption-${subscription.id}-payment`}
          >
            <div>
              <Label text="Payment Details" classNames={['accounts-subscription-item__content__section__label']} />
              <PaymentDropdown key={subscription?.paymentMethodId} subscription={subscription} />
            </div>
          </Container>
        )}
        {/* <Label text="Payment Details" classNames={['accounts-subscription-item__content__section__label']} />
            <Container flexbox flexDirection="row" background="none" justifyContent="flex-start" alignItems="center">
              <Icon name="payments_visa" size="xlarge" />
              <div>
                <Text text="Credit card ••••1234" classNames={['ml-3']} />
              </div>
              <div>
                <Link to="/wallet" className="ml-3">
                  Change
                </Link>
              </div>
            </Container> */}
      </div>
    </>
  );
};
