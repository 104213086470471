import { FriendlyName, ProductTier } from '@legalshield/frontend-commons/dist/sdk/products';
import Cookies from 'js-cookie';
import { ISubscriptionResource } from '../hooks/utils/subscription-utils';
import { OfferItem } from '@legalshield/frontend-commons/dist/sdk/subscriptions';

const locale = Cookies.get('market') || '';

export const getLocale = () => {
  const language = locale?.split('-')[0]?.toLowerCase();
  return language || 'en';
};

export const getFriendlyNameByLocale = (friendlyNames: FriendlyName[], preferredLocale: string) => {
  if (!friendlyNames)
    return '';

  return friendlyNames.find(fn => fn.locale?.toLowerCase() === preferredLocale)?.name ||
    friendlyNames.find(fn => fn.locale?.toLowerCase() === 'en')?.name ||
    friendlyNames[0]?.name ||
    '';
};

const currentLocale = getLocale();

export const getFriendlyProductName = (subscription: ISubscriptionResource) => {
  if (!subscription?.productBrochure)
    return '';

  const offerItems = subscription.offer?.offerItems ?? [];
  const friendlyTierName = getFriendlyTierName(subscription.productBrochure.tier, offerItems);
  const productFriendlyName = getFriendlyNameByLocale(subscription.productBrochure.friendlyNames, currentLocale);
  return combineDistinct(productFriendlyName, friendlyTierName) + getSupplementText(offerItems);
};

function getFriendlyTierName(tiers: ProductTier[], offerItems: OfferItem[]): string {
  const offerItem = offerItems.find(oi => oi.type === 'PRODUCT') || offerItems.find(() => true);
  if (!offerItem)
    return '';

  const matchedTier = findMatchingTier(tiers, offerItem.productDetail?.productTier);
  return getFriendlyProductTierName(matchedTier);
};

function findMatchingTier(tiers: ProductTier[], tierName: string): ProductTier | undefined {
  return tiers?.find(t => t.name === tierName);
};

/**
 * Get the friendly name of a tier in the current locale, fall back to the tier name and finally an empty string.
 * @param tier Tier from which the friendly name will be derived.
 * @returns Tier friendlyName, name, or an empty string.
 */
function getFriendlyProductTierName(tier: ProductTier): string {
  if (!tier)
    return '';

  const friendlyName = getFriendlyNameByLocale(tier.friendlyNames, currentLocale);
  return !isNullOrWhitespace(friendlyName) ? friendlyName : tier.name || '';
};

function getSupplementText(offerItems: OfferItem[]): string {
  const hasSupplement = offerItems.some(oi => oi.type === 'SUPPLEMENT');
  return hasSupplement ? ' w/ Supplements' : '';
};

// https://stackoverflow.com/a/32800728/4119585
function isNullOrWhitespace(input: string): boolean {
  return !input || !input.trim();
};

function combineDistinct(string1: string, string2: string) {
  if (string2.includes(string1))
    return string2.trim();

  return `${string1} ${string2}`.trim();
}
