import React from 'react';
import { Card, Container, Heading, Image, Text } from '@legalshield/adonis-ux-framework';

import { Benefits } from './Benefits/Benefits';
import { ISubscriptionResource } from '../hooks/utils/subscription-utils';
import { formatDate, getLogo } from '../utils/utils';
import { getBillingFrequency } from '../utils/getBillingFrequency';
import { getFriendlyProductName } from '../utils/getFriendlyName';
import { useGetGroupByGroupId } from '../hooks/useGetGroup';
import { PayType } from '@legalshield/frontend-commons/dist/sdk/groups';
import { Users } from '@legalshield/frontend-commons/src/utils/users';

type ProductInfoProps = {
  subscription: ISubscriptionResource;
};

export const ProductInfo = ({ subscription }: ProductInfoProps) => {
  const { data: groupData } = useGetGroupByGroupId(subscription?.source?.groupId);

  const productBrochure = subscription?.productBrochure;
  const productFamily = productBrochure?.productFamily;

  const logo = getLogo(productFamily);

  const memberSince = formatDate(subscription?.startedOn);
  const nextBillOn = formatDate(subscription?.nextBillOn);
  const price = `$${subscription?.price?.toFixed(2)}`;
  const billingPeriod = getBillingFrequency(subscription.billingFrequency.period);

  const isSelfPay = !groupData || groupData?.payType === PayType.SELF_PAY;
  const isImpersonated = !!Users.isImpersonated();

  return (
    <Card id="product-info" classNames={['mb-5']}>
      <Card.Content>
        <Container flexbox={true} classNames={['align-items-end mb-5']}>
          <Heading id="product-name" as="T20" text={getFriendlyProductName(subscription)} />
          <Image
            id="logo"
            classNames={['ml-auto']}
            src={'https://design.api.' + pplsi.realmDomain + '/assets/logos/' + logo}
            alt={string_table.PRODUCT_LOGO_ALT}
          />
        </Container>
        <Container flexbox={true} classNames={['align-items-end mb-5']}>
          <Container>
            <Text id="member-since" text={`${string_table.PRODUCT_MEMBER_SINCE_DATE} ${memberSince}`} />
            <br />
            {(isSelfPay || isImpersonated) && (
              <Text id="next-billing-date" text={`${string_table.PRODUCT_NEXT_BILL_DATE} ${nextBillOn}`} />
            )}
          </Container>
          {(isSelfPay || isImpersonated) && (
            <Container id="price-container" classNames={['ml-auto', 'mb-5']}>
              <Text id="price" textWeight="bold" textSize="extra-large" text={price} />
              <Text
                id="billing-freq"
                textSize="tiny"
                text={`${string_table.PRODUCT_BILL_FREQUENCY} ${billingPeriod}`}
              />
            </Container>
          )}
        </Container>
        <Benefits subscription={subscription} />
      </Card.Content>
    </Card>
  );
};
